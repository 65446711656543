import "./App.css";
import { fabric } from "fabric";
import { useEffect, useRef, useState } from "react";
// import { io } from "socket.io-client";
// import { v4 as uuid } from "uuid"
import useWindowDimensions from "./hooks/use-window-dimensions";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyA8RYtS0UtK5R0ksP7hefVGi-RirKnCgDk",
  authDomain: "draw-f3433.firebaseapp.com",
  projectId: "draw-f3433",
  storageBucket: "draw-f3433.appspot.com",
  messagingSenderId: "810608921948",
  appId: "1:810608921948:web:29dadcb89aa038bb734599",
  measurementId: "G-7RQ6ZGRSEZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const canvas: any = useRef(null);
  // const clientId = uuid()
  const [mode, setMode] = useState<string>("draw");
  const { height, width } = useWindowDimensions();
  // const socket = io()
  const STATE_IDLE = "idle";
  const STATE_PANNING = "panning";
  let lastClientX: any;
  let lastClientY: any;

  let currentState = STATE_IDLE;

  const switchMode = (newMode: string) => {
    setMode(newMode);
  };

  // const emitUpdateCanvas = (event: any) => {
  //  socket.emit('update', {canvasInJson: canvas.current.toJSON(), foreignClientId: clientId })
  // }∏∏

  useEffect(() => {
    console.log(mode);
    if (canvas.current) {
      if (mode === "draw") {
        canvas.current.isDrawingMode = true;
      } else {
        canvas.current.isDrawingMode = false;
      }
      setEventListeners(canvas.current);
    }
  }, [mode]);

  useEffect((): any => {
    canvas.current = new fabric.Canvas("canvas");
    canvas.current.fireMiddleClick = true;
    canvas.current.isDrawingMode = true;
    canvas.current.backgroundColor = null;

    canvas.current.renderAll.bind(canvas.current)();

    // socket.on('update', ({canvasInJson, foreignClientId}: any) => {
    //   if (clientId === foreignClientId) return
    //   canvas.current.loadFromJSON(canvasInJson)
    // })

    setEventListeners(canvas.current);

    // return () => socket.disconnect()
  }, []);

  function togglePanning(isPanning: boolean, canvas: any) {
    canvas.discardActiveObject();
    canvas.selection = isPanning;

    canvas.setCursor(isPanning ? "move" : "default");

    canvas.forEachObject((object: any) => {
      object.evented = !isPanning;
      object.selectable = !isPanning;
    });
  }

  const setEventListeners = (canvas: any) => {
    canvas.off("touch:gesture").on("touch:gesture", (event) => {
      alert("sup");
      // // Handle zoom only if 2 fingers are touching the screen
      // let zoomStartScale = canvas.getZoom();
      // if (event.e.touches && event.e.touches.length === 2) {
      //   // Get event point
      //   var point = new fabric.Point(event.self.x, event.self.y);
      //   // Remember canvas scale at gesture start
      //   if (event.self.state == "start") {
      //     zoomStartScale = canvas.getZoom();
      //   }
      //   // Calculate delta from start scale
      //   var delta = zoomStartScale * event.self.scale;
      //   // Zoom to pinch point
      //   canvas.zoomToPoint(point, delta);
      // }
    });
    canvas
      .off("mouse:up")
      .on("mouse:up", (e: any) => {
        if (e.button === 2 || mode === "drag") {
          currentState = STATE_IDLE;
          togglePanning(false, canvas);
        }
        // emitUpdateCanvas(e)
      })
      .off("mouse:down")
      .on("mouse:down", (e: any) => {
        if (e.button === 2 || mode === "drag") {
          currentState = STATE_PANNING;
          togglePanning(true, canvas);
          lastClientX = e.e.clientX;
          lastClientY = e.e.clientY;
        }
      })
      .off("mouse:move")
      .on("mouse:move", (event: any) => {
        if (currentState === STATE_PANNING && event) {
          let deltaX = 0;
          let deltaY = 0;

          if (lastClientX) deltaX = event.e.clientX - lastClientX;
          if (lastClientY) deltaY = event.e.clientY - lastClientY;

          lastClientX = event.e.clientX;
          lastClientY = event.e.clientY;

          const delta = new fabric.Point(deltaX, deltaY);
          canvas.relativePan(delta);
        }
      })
      .off("mouse:wheel")
      .on("mouse:wheel", function (this: any, opt: any) {
        var delta = opt.e.deltaY;
        var zoom = canvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 10) zoom = 10;
        if (zoom < 0.1) zoom = 0.1;
        canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
      });
  };

  return (
    <div className="App">
      <canvas
        id="canvas"
        width={width}
        height={height}
        style={{ border: "1px solid #000" }}
      ></canvas>
      <div className="tools mb-2">
        <div className="inline-flex rounded-md shadow-sm" role="group">
          <button
            type="button"
            onClick={() => switchMode("draw")}
            className={`${
              mode === "draw"
                ? "text-white bg-blue-700 hover:bg-blue-800 border-blue-800 "
                : "bg-gray-700 border-gray-600 text-white hover:text-white hover:bg-gray-600 focus:ring-blue-500 focus:text-white"
            } inline-flex items-center py-2 px-4 text-sm font-medium border-t border-b focus:z-10 focus:ring-2 rounded-l-lg`}
          >
            Draw
          </button>
          <button
            type="button"
            onClick={() => switchMode("drag")}
            className={`${
              mode === "drag"
                ? "text-white bg-blue-700 hover:bg-blue-800 border-blue-800 "
                : "bg-gray-700 border-gray-600 text-white hover:text-white hover:bg-gray-600 focus:ring-blue-500 focus:text-white"
            } inline-flex items-center py-2 px-4 text-sm font-medium border-t border-b focus:z-10 focus:ring-2 `}
          >
            Drag
          </button>
          <button
            onClick={() => switchMode("select")}
            type="button"
            className={`${
              mode === "select"
                ? "text-white bg-blue-700 hover:bg-blue-800 border-blue-800 "
                : "bg-gray-700 border-gray-600 text-white hover:text-white hover:bg-gray-600 focus:ring-blue-500 focus:text-white"
            } inline-flex items-center py-2 px-4 text-sm font-medium border-t border-b focus:z-10 focus:ring-2 rounded-r-md`}
          >
            Select
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
